import React, { useState, useEffect } from 'react';

import styles from './app.module.scss';
import '@sl-it-dev/sl-react/dist/index.css';

import pidClient from './api/pidClient';

import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Info from './pages/Info';
import Order from './pages/Order';

const App = () => {

    const [loading, setLoading] = useState(true);
    const [pid, setPid] = useState(null);

    const [product, setProduct] = useState(null);

    useEffect(() => {
        const url = new URL(window.location);
        const pid = url.searchParams.get('pid');
        setPid(pid);
        pidClient.get(`/product/${pid}`)
            .then((res) => {
                if (res.data.nanaOrder) {
                    window.location.href = 'https://www.nanawall.com/';

                    return;
                }

                setProduct(res.data.product);
                setLoading(false);
            })
        ;
    }, []);

    return (
        <div className={styles.afterSales}>
            {null !== product && <Router basename="/apps/serviceportal">
                <Switch>
                    <Route path="/order">
                        <Order pid={pid} product={product} loading={loading} />
                    </Route>
                    <Route path="/">
                        <Info pid={pid} product={product} loading={loading} />
                    </Route>
                </Switch>
            </Router>}
        </div>
    );
}

export default App;
